import { createGlobalStyle, css } from 'styled-components';
import { reset } from 'styled-reset';
import './fonts.css';
import { ThemeVarsProps } from '@atlas/statics/lib/types/variables';
import { media } from '@atlas/ui-library/lib/breakpoints';
import { themeVarsToCss } from '@atlas/ui-library/lib/themeVarsToCSS';

const previews = css`
	#preview-pane {
		border-radius: 0;
	}
`;

export const GlobalStyle = createGlobalStyle<{
	$variables: ThemeVarsProps;
}>`
  ${reset}

  :root {
    ${({ $variables }) => themeVarsToCss($variables)}
  }

  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    &:focus-visible {
      outline-width: 2px;
      outline-style: solid;
      outline-color: var(--color-primary-_500)
    }

    @supports not selector(:focus-visible) {
      &:focus {
        outline-width: 2px;
        outline-style: solid;
        outline-color: var(--color-primary-_500)
      }
    }
  }

  html,
  body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display:flex;
    flex-direction: column;
  }

  /* Texts */
  em { font-style: italic; }
  strong { font-weight: bold; }

  /* Lists */
  ol, ul {
    margin-block-end: 1em;
    padding-inline-start: var(--spacing-_40);
    li {
      text-align: -webkit-match-parent;
    }
  }

  ul { list-style: disc; }
  ol { list-style: auto; }
  
  /* Links */
  a {
    margin-bottom: 0;
    color: var(--color-text-link);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  
  /* Headings */
  
  h1 {
    font-size: var(--font-title-size-XL-mobile);
    ${media.md`
      font-size: var(--font-title-size-XL-tablet);
    `}
    ${media.lgMin`
      font-size: var(--font-title-size-XL-laptop);
    `}
  }

  h2 {
    font-size: var(--font-title-size-L-mobile);
    ${media.md`
      font-size: var(--font-title-size-L-tablet);
    `}
    ${media.lgMin`
      font-size: var(--font-title-size-L-laptop);
    `}
  }

  h3 { 
    font-size: var(--font-title-size-M-mobile);
    ${media.md`
      font-size: var(--font-title-size-M-tablet);
    `}
    ${media.lgMin`
      font-size: var(--font-title-size-M-laptop);
    `}
  }

  h4 {
    font-size: var(--font-title-size-S-mobile);
    ${media.md`
      font-size: var(--font-title-size-S-tablet);
    `}
    ${media.lgMin`
      font-size: var(--font-title-size-S-laptop);
    `}
  }

  h5 {
    font-size: var(--font-title-size-XS-mobile);
    ${media.md`
      font-size: var(--font-title-size-XS-tablet);
    `}
    ${media.lgMin`
      font-size: var(--font-title-size-XS-laptop);
    `}
  }
  ${previews}
`;

export default GlobalStyle;
